import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {LoadingOutlined, DownOutlined} from '@ant-design/icons';
import {isValidDate, frenchFormat} from '../../../../utils/dateFormatting';

import Loader from '../../../loaders/Loader';

import './select-input.css'

/**
 * Composant SelectInput
 *
 * @component
 * @param {Object} props - Les props du composant
 * @param {Object} props.value - La valeur sélectionnée
 * @param {Function} props.onChange - La fonction de rappel appelée lorsque la sélection change (React Hook Form)
 * @param {Array} props.options - Les options disponibles pour le dropdown
 * @param {React.Ref} props.ref - La référence de l'input du dropdown
 * @param {Function} props.fetchFunction - La fonction de récupération des options supplémentaires (Optionnel)
 * @param {Array} props.labelKeys - Les clés des labels à afficher pour chaque option
 * @param {string} [props.placeholder='Sélectionnez une option'] - Le texte de l'espace réservé du dropdown (par défaut : 'Sélectionnez une option')
 * @returns {JSX.Element} Composant SelectInput
 */

const getOptionStyles = (borderColor, backgroundColor, menuMaxHeight, inputMinHeight, inputHeight) => ({
    control: (baseStyles, { isFocused }) => ({
        ...baseStyles,
        border: isFocused ? `1px solid ${borderColor}` : '1px solid #D9D9D9',
        borderRadius: '0',
        outline: 'none',
        boxShadow: 'none',
        height: inputHeight ?? '31px',
        minHeight: inputMinHeight ?? '31px',
        marginTop: '5px',
        ':hover': {
            ...baseStyles[':hover'],
            border: `1px solid ${borderColor}`,
            cursor: 'pointer'
        }
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        height: 'fit-content',
        maxHeight: menuMaxHeight
    }),
    option: (baseStyles, { isFocused, isSelected, isDisabled }) => ({
        ...baseStyles,
        background: isDisabled
            ? undefined
            : isSelected
                ? backgroundColor
                : isFocused
                    ? backgroundColor
                    : undefined,
        color: isFocused ? 'white' : isSelected ? 'white' : 'black',
        fontSize: '14px',
        minHeight: '28px',
        height: 'fit-content',
        margin: '0',
        padding: '3px 10px 0',
        ':active': {
            ...baseStyles[':active'],
            backgroundColor: !isDisabled
                ? isSelected
                    ? backgroundColor
                    : borderColor
                : undefined
        }
    })
});

const SelectInput = ({
    onChange,
    options,
    value,
    ref,
    error,
    disabled,
    loading = false,
    fetchFunction,
    labelKeys,
    label = '',
    placeholder = 'Sélectionnez une option',
    separator = ' ',
    required = false,
    width = 'w-[250px]',
    labelWidth = 'w-36',
    blueStyle = false,
    isSearchable = false,
    isClearable = false,
    hasNextPage = false,
    menuHeight = '350px',
    isMulti = false,
    maximumItemSelectable = 10,
    minHeight = null,
    height = null
}) => {
    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(null);

    const borderColor = blueStyle ? '#19b3cc' : '#E36D38';
    const backgroundColor = blueStyle
        ? 'linear-gradient(135deg, #00ABE9 0%, #00ABAB 40.62%, #CED955 100%)'
        : 'linear-gradient(135deg, #DC3832 0%, #E36D38 46.35%, #F0A841 100%)';

    const optionStyles = getOptionStyles(borderColor, backgroundColor, menuHeight, minHeight, height);

    useEffect(() => {
        setDefaultValue(getDefaultValue());
    }, [value]);

    const createOptionsWithLabel = (data) => {
        return data.map((option) => {

            let label = labelKeys.map((key) => {
                if (key.includes(".")){
                    const newkey = key.split(".")

                    return isValidDate(option[newkey[0]][newkey[1]]) ? frenchFormat(option[newkey[0]][newkey[1]]) : option[newkey[0]][newkey[1]]
                }
                else {
                    return isValidDate(option[key]) ? frenchFormat(option[key]) : option[key]
                }
            }).join(separator);

            const value = option;

            return { label, value };
        });
    };

    const getDefaultValue = () => {
        if (Array.isArray(value)) {
            return value.map(el => {
                const label = labelKeys.map((key) => el[key]).join(separator);

                return { label: label, value: el };
            });
        }
        else if (value) {
            const label = labelKeys.map((key) => {
                if (key.includes(".")){
                    const newkey = key.split(".")

                    return value[newkey[0]][newkey[1]]
                }
                else {
                    return value[key]
                }
            }).join(separator);

            return { label, value };
        }
        else {
            return null;
        }
    };

    useEffect(() => {
        if (!options) return;

        setDisplayedOptions(createOptionsWithLabel(options));
    }, [options]);

    useEffect(() => {
        if (fetchFunction && searchTerm) {
            setFetchLoading(true);

            const timer = setTimeout(() => {
                setDebouncedSearchTerm(searchTerm);
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        }
        else {
            setDebouncedSearchTerm('');
        }
    }, [searchTerm]);

    useEffect(() => {
        if (fetchFunction) {
            (async () => {
                if (debouncedSearchTerm) {
                    await fetchFunction(debouncedSearchTerm);
                }
                else {
                    await fetchFunction('', 1);
                }
                setFetchLoading(false);
            })();
        }
    }, [debouncedSearchTerm]);

    const handleLoadMore = async () => {
        if (fetchLoading || !fetchFunction || searchTerm || debouncedSearchTerm || !hasNextPage) {
            return;
        }

        if (fetchFunction && !fetchLoading) {
            setFetchLoading(true);

            const nextPage = currentPage + 1;
            await fetchFunction('', nextPage);
            setCurrentPage(nextPage);

            setFetchLoading(false);
        }
    };

    const handleChange = (selectedOption) => {
        if (isMulti) {
            // ↓ cette vérification empêche une erreur lors de la première selection (comme par exemple si la valeur en props est une string vide)
            const precautionaryArray = Array.isArray(defaultValue) ? defaultValue : [];

            const assimilateDuplicates = selectedOption.reduce((accumulator, current) => {
                if (!accumulator.some(item => item.value.id === current.value.id)) {
                    accumulator.push(current);
                }

                return accumulator;
            }, []);

            if (precautionaryArray.length < maximumItemSelectable || assimilateDuplicates.length < precautionaryArray.length) {
                const values = assimilateDuplicates.map(el => el.value);

                onChange(values);
            }
        }
        else {
            onChange(selectedOption ? selectedOption.value : null);
        }
    };

    const handleInputChange = async (inputValue) => {
        if (inputValue === '') {
            setSearchTerm('');
            setFetchLoading(false);

            return;
        }

        setSearchTerm(inputValue);
    };

    console.log(value);

    return (
        <div className='flex flex-col'>
            <div className='flex flex-row items-center justify-between w-full'>
                {
                    label ?
                        <p className={`${labelWidth} font-normal text-sm text-[#646464]`}>
                            {label} {required && <span className='text-red-500'>*</span>}
                        </p>
                        : null
                }
                <div className={`${width} relative ${height ?? "h-[31px]"}`}>
                    <Select
                        inputRef={ref}
                        options={displayedOptions}
                        isClearable={isClearable}
                        clearValue={() => setSearchTerm('')}
                        inputValue={searchTerm}
                        value={defaultValue}
                        required={required}
                        isSearchable={isSearchable}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onMenuScrollToBottom={handleLoadMore}
                        noOptionsMessage={fetchLoading ? () => 'Chargement...' : () => "Aucune option"}
                        placeholder={placeholder.length > 25 ? placeholder.slice(0, 25) + '...' : placeholder}
                        styles={optionStyles}
                        classNamePrefix="select-input"
                        components={{
                            DropdownIndicator: fetchLoading ?
                                () =>  <LoadingOutlined style={{margin: '0 10px'}}/> :
                                () => <DownOutlined style={{margin: '0 10px', fontSize: '13px'}}/>,
                            IndicatorSeparator: () => null
                        }}
                        isDisabled={disabled}
                        isMulti={isMulti}
                    />
                    {
                        loading ?
                            <div className="absolute border border-[#D9D9D9] top-0 left-0 w-full h-full bg-[#EFEFEF]">
                                <div className="flex flex-row items-center justify-center w-full h-full">
                                    <Loader size={10}/>
                                    <span className="ml-1 text-xs text-[#646464]">Chargement</span>
                                </div>
                            </div>
                            : null
                    }
                </div>
                {
                    isMulti ?
                        <div className='font-normal text-sm text-[#646464] ml-4'>
                            {`${Array.isArray(value) ? value.length : 0}/${maximumItemSelectable}`}
                        </div>
                        : null
                }
            </div>
            {
                error &&
                <div className="text-xs italic text-red-500">
                    {error.message}
                </div>
            }
        </div>
    );
};

export default SelectInput;
