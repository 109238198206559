import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { formatThousands } from '../../../../utils/numberFormatting';
import {
    ContractorActions,
    DealActions,
    UserActions
} from '../../../../actions';
import Button from '../../../../components/ui/Button';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { notify } from '../../../../components/ui/Toast/Toast';
import { fetchSelectData, getErrors } from '../../../../utils';

const BudgetPopup = ({
    setView,
    data,
    refreshData,
    blueStyle
}) => {
    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const [ressources, setRessources] = useState([]);
    const [contractors, setContractors] = useState([]);

    const userData = useSelector((state) => state.UserReducer.userProfileData);

    const [partnerValues, setPartnerValues ] = useState([]);
    const [partnerLabels, setPartnerLabels] = useState([]);

    // -- react hook form --
    const defaultValues = {
        customer: '',
        short_name: '',
        name: '',
        subsidiary: '',
        das: '',
        type: '',
        origin: ''
    };
    const {handleSubmit, control, reset, formState: {isValid}, watch} = useForm({defaultValues: defaultValues});

    // -- total without --
    let totalWithoutPartners = (parseInt(watch('expense_budget')) || 0) +  (parseInt(watch('procurement_cost')) || 0) + (parseInt(watch('commission')) || 0) + (parseInt(watch('budget_global')) || 0);

    // -- Add pilots budget --
    if (data?.agreement_leads) {
        data?.agreement_leads?.forEach((_pilot, index) => {
            const value = watch(`agreement_budget_${index}`);
            totalWithoutPartners += parseInt(value) || 0;
        })
    }

    let totalWithPartners = totalWithoutPartners;

    const partnersWithCustomer = partnerValues.filter((partner) => partner?.contractor);

    // -- Add partners budget --
    if (partnersWithCustomer.length) {
        partnersWithCustomer.forEach((partner, index) => {
            const fieldName = `payment_${partner.contractor?.name ? partner.contractor?.name : `partner_${index}`}`;
            const watchPayment = watch(fieldName);

            // -- If direct payment, skip or if no payment --
            if(!watchPayment || watchPayment?.direct) return;

            const value = watch(`partner_budget_${partner.contractor?.name ? partner.contractor?.name : `partner_${index}`}`);
            totalWithPartners += parseInt(value) || 0;
        })
    }

    const handleGetRessources = async (search, page = 1) => {
        await fetchSelectData({action: UserActions.getAllSortedUsers, search:`&search=${search}`, page: page || null, setter: setRessources, userData, resultPath: 'data.results'});
    };
    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({action: ContractorActions.getAllContractors, search:`&search=${search}`, page: page, setter: setContractors, userData, resultPath: 'data'});
    };

    // Get dropdows data
    useEffect(() => {

        // ↓ Ressources dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();
        // ↓ Contractors dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();
    }, []);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            let resetObject;
            data.contractors?.forEach((partner) => {
                resetObject = {...resetObject, ...{
                    [`partner_${partner.contractor?.name}`]: partner.contractor,
                    [`partner_budget_${partner.contractor?.name}`]: partner.budget,
                    [`payment_${partner.contractor?.name}`]: {id: partner.direct ? 1 : 2, direct: partner.direct, label: partner.direct ? 'Direct' : 'Indirect'},
                    [`vat_rate_${partner.contractor?.name}`]: partner.budget_vat
                }}
            });

            if (data.type === "purchase_order" || data.type === "framework") {
                resetObject = {...resetObject, ...{
                    min_budget: data.min_budget,
                    min_days_of_intervention: data.min_days_of_intervention,
                    min_vat_rate: data.min_vat_rate,
                    max_budget: data.max_budget,
                    max_days_of_intervention: data.max_days_of_intervention,
                    max_vat_rate: data.max_vat_rate

                }}
            }

            if (data.type === "business" && data.parent) {
                resetObject = {...resetObject, ...{
                    budget_global: data.budget,
                    billable_days_global: data.billable_days,
                    vat_rate_global: data.budget_vat,
                    expense_budget: data.expense_budget,
                    procurement_cost: data.procurement_cost,
                    commission: data.commission
                }}

                data?.agreement_leads?.forEach((pilot, index) => {
                    resetObject = {...resetObject, ...{
                        [`agreement_lead_${index}`]: pilot.ressource,
                        [`agreement_budget_${index}`]: pilot.budget,
                        [`agreement_daily_rate_${index}`]: pilot.daily_rate
                    }}
                });
            }

            if (data.type === "business" && !data.parent) {
                resetObject = {...resetObject, ...{
                    budget_global: data.budget,
                    billable_days_global: data.billable_days,
                    vat_rate_global: data.budget_vat,
                    expense_budget: data.expense_budget,
                    procurement_cost: data.procurement_cost,
                    commission: data.commission
                }}
            }

            reset(resetObject)
        }
    }, [data]);

    useEffect(() => {
        if (!data.contractors) return;
        setPartnerValues(data.contractors);
    }, [data])

    useEffect(() => {
        if (!partnerValues.length) return;
        setPartnerLabels(partnerValues.map((partner) => partner.contractor?.name || []));
    }, [partnerValues])

    // Submit form
    const onSubmit = async (formData) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        let updatedDeal;

        if (data.type === "purchase_order" || data.type === "framework") {
            let {min_budget, min_days_of_intervention, min_vat_rate, max_budget, max_days_of_intervention, max_vat_rate, ...rest} = formData;
            const dynamicKeys = Object.keys(rest);
            const contractorNames = new Set();

            dynamicKeys.forEach(key => {
                const name = key.split('_').slice(2).join('_');
                name && contractorNames.add(name);
            });

            // Create an array of contractor objects
            const contractors = Array.from(contractorNames).map(name => {
                return {

                    name,
                    contractor_id: rest[`partner_${name}`].id,
                    budget: rest[`partner_budget_${name}`],
                    direct: rest[`payment_${name}`].direct,
                    budget_vat: rest[`vat_rate_${name}`],
                    contractors
                };
            });

            updatedDeal = {
                min_budget: formData.min_budget,
                min_days_of_intervention: formData.min_days_of_intervention,
                min_vat_rate: formData.min_vat_rate,
                max_budget: formData.max_budget,
                max_days_of_intervention: formData.max_days_of_intervention,
                max_vat_rate: formData.max_vat_rate
            }
        }

        if (data.type === "business" && data.parent) {
            let {budget_global, billable_days_global, vat_rate_global, expense_budget, procurement_cost, commission, agreement_budget, agreement_lead, ...rest} = formData;
            const dynamicKeys = Object.keys(rest);

            const contractorNames = new Set();

            dynamicKeys.forEach(key => {
                const prefix = key.split('_')[0];

                if (prefix === 'agreement') return;

                const name = key.split('_').slice(2).join('_');

                name && contractorNames.add(name);
            });

            // Create an array of contractor objects
            const contractors = Array.from(contractorNames).map(name => {
                return {
                    name,
                    contractor_id: rest[`partner_${name}`].id,
                    budget: rest[`partner_budget_${name}`],
                    direct: rest[`payment_${name}`].direct,
                    budget_vat: rest[`vat_rate_${name}`]
                };
            });

            const agreementLeads = data?.agreement_leads?.map((pilot, index) => {
                return {
                    ressource_id: formData[`agreement_lead_${index}`].id,
                    daily_rate: formData[`agreement_daily_rate_${index}`],
                    budget: formData[`agreement_budget_${index}`]
                }
            });

            updatedDeal = {
                budget: formData.budget_global,
                billable_days: formData.billable_days_global,
                budget_vat: formData.vat_rate_global,
                expense_budget: formData.expense_budget,
                procurement_cost: formData.procurement_cost,
                commission: formData.commission,
                agreement_leads: agreementLeads,
                contractors
            }
        }

        if (data.type === "business" && !data.parent) {

            let {budget_global, billable_days_global, vat_rate_global, expense_budget, procurement_cost, commission, ...rest} = formData;

            const dynamicKeys = Object.keys(rest);
            const contractorNames = new Set();

            dynamicKeys.forEach(key => {
                const name = key.split('_').slice(2).join('_');
                name && contractorNames.add(name);
            });

            // Create an array of contractor objects
            const contractors = Array.from(contractorNames).map(name => {
                return {

                    name,
                    contractor_id: rest[`partner_${name}`].id,
                    budget: rest[`partner_budget_${name}`],
                    direct: rest[`payment_${name}`].direct,
                    budget_vat: rest[`vat_rate_${name}`]
                };
            });

            // const contractors = objectKeys.map((key, index) => {
            //     return {
            //         budget: rest[key],
            //         direct: formData[`payment_${key}`].direct,
            //         contractor: {name: key},
            //         budget_vat: formData[`vat_rate_${key}`]}
            // });

            updatedDeal = {
                budget: formData.budget_global,
                billable_days: formData.billable_days_global,
                budget_vat: formData.vat_rate_global,
                expense_budget: formData.expense_budget,
                procurement_cost: formData.procurement_cost,
                commission: formData.commission,
                contractors
            }
        }

        const result = await DealActions.updateDeal(userData, updatedDeal, data.id);

        if (!result.success) {
            setErrors(getErrors(result));
        }
        else {
            await refreshData();
            notify('Les informations ont été mis à jour');
            setView(false);
            reset();
        }

        setLoading(false);
        setDisabled(false);
    }

    const filterOutAlreadySelected = (options, selected) => {
        if (!options) return [];

        if (!selected) return options;
        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.name === obj2?.contractor?.name
            )
        );

        return filteredArray;
    };

    const filterOutPilots = (options) => {

        const pilotValuesArray = data.parent.producers.map((pilot, index) => {
            const watchPilot = watch(`agreement_lead_${index}`);

            if (watchPilot) {
                return watchPilot;
            }
        });

        if (!pilotValuesArray[0]) return options;

        var selected = pilotValuesArray.filter(Boolean);

        // Corrected filter function with explicit return statement
        const filteredArray = options.filter((pilotOption) => {
            return !selected.some(obj2 =>
                pilotOption?.id === obj2?.id
            );
        });

        return filteredArray;
    };

    return (
        <ContainerCard
            title={data.name}
            coloredButtonDisplayed={false}
            overflow='overflow-visible'
        >
            <div className="flex justify-start text-gradient w-[110% TVA] pb-2 pl-2 mb-6 pt-4 border-b border-gray-200">
                    Honoraires
            </div>

            {data.type === "purchase_order" || data.type === "framework" ?
                <div className='w-full'>
                    <div className='w-full pb-4 mb-4 border-b border-gray-200'>
                        <div className="flex items-center justify-between w-full gap-4 pb-1">
                            <label className="w-[170px] font-normal text-sm text-[#646464]">{data.subsidiary}
                            </label>
                            <div className='flex items-center'>
                                <Controller
                                    name="min_budget"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le budget'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[200px]'
                                            labelWidth='w-[80px]'
                                            label="Minimum"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Budget HT"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="min_days_of_intervention"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le nombre des jours'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[150px]'
                                            label=''
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    Jours
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="min_vat_rate"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir la tva'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[100px]'
                                            label=''
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Taux TVA"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    % TVA
                                </label>
                            </div>
                        </div>
                        <div className="flex items-center justify-between w-full gap-4 pb-1">
                            <label className="w-[170px] font-normal text-sm text-[#646464]"></label>
                            <div className='flex items-center'>
                                <Controller
                                    name="max_budget"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le budget'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[200px]'
                                            labelWidth='w-[80px]'
                                            label="Minimum"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Budget HT"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="max_days_of_intervention"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le nombre des jours'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[150px]'
                                            label=''
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    Jours
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="max_vat_rate"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir la tva'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[100px]'
                                            label=''
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Taux TVA"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    % TVA
                                </label>
                            </div>
                        </div>
                    </div>
                </div> :
                <div className='w-full'>
                    <div className='w-full pb-4 mb-4'>
                        <div className="flex justify-between w-full gap-4 pb-1">
                            <div className='flex items-center'>
                                <Controller
                                    name="budget_global"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le budget'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            // width='w-[100px]'
                                            label={data.subsidiary}
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Honoraires HT"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="billable_days_global"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le nombre des jours'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            // width='w-[100px]'
                                            label=''
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Jours d'intervention"
                                        // labelWidth='w-40'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    Jours
                                </label>
                            </div>
                            <div className='flex items-center'>
                                <Controller
                                    name="vat_rate_global"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir la tva'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            width='w-[100px]'
                                            label=''
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Taux TVA"
                                            type="number"
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    % TVA
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-4 pb-1">
                            <div className='flex items-center'>
                                <Controller
                                    name="expense_budget"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le budget'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            // width='w-[100px]'
                                            label="Achats forfaitaires refacturés"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Achats forfaitaires refacturés"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-4 pb-1">
                            <div className='flex items-center'>
                                <Controller
                                    name="procurement_cost"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le budget'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            // width='w-[100px]'
                                            label="Frais forfaitaires refacturés"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Frais forfaitaires refacturés"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-4 pb-1">
                            <div className='flex items-center'>
                                <Controller
                                    name="commission"
                                    control={control}
                                    rules={{required: {
                                        value: true,
                                        message: 'Veuillez saisir le budget'
                                    }}}
                                    render={({
                                        field: {onChange, value},
                                        fieldState: {error}
                                    }) => (
                                        <TextInput
                                            color={blueStyle ? 'blue' : 'orange'}
                                            // width='w-[100px]'
                                            label="Commissions"
                                            onChange={onChange}
                                            value={value}
                                            error={error}
                                            required={true}
                                            disabled={disabled}
                                            placeholder="Comissions"
                                            type="number"
                                            labelWidth='w-56'
                                        />
                                    )}
                                />
                                <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                </label>
                            </div>
                        </div>
                        {data.type === "business" && data.parent ?
                            data?.agreement_leads?.map((pilot, index) => {
                                return (
                                    <div key={index} className="flex items-center justify-between gap-4 pb-1">
                                        <div className='flex items-center'>
                                            <Controller
                                                name={`agreement_budget_${index}`}
                                                control={control}
                                                rules={{required: {
                                                    value: true,
                                                    message: 'Veuillez saisir le budget'
                                                }}}
                                                render={({
                                                    field: {onChange, value},
                                                    fieldState: {error}
                                                }) => (
                                                    <TextInput
                                                        color={blueStyle ? 'blue' : 'orange'}
                                                        // width='w-[100px]'
                                                        label="Budget pilotage"
                                                        onChange={onChange}
                                                        value={value}
                                                        error={error}
                                                        required={true}
                                                        disabled={disabled}
                                                        placeholder="Budget HT"
                                                        type="number"
                                                        labelWidth='w-56'
                                                    />
                                                )}
                                            />
                                            <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                            </label>
                                        </div>
                                        <div className='ml-6'>
                                            <Controller
                                                name={`agreement_lead_${index}`}
                                                control={control}
                                                rules={{required: {
                                                    value: true,
                                                    message: 'Veuillez sélectionner une ressource'
                                                }}}
                                                render={({
                                                    field: {onChange, value, ref},
                                                    fieldState: {error}
                                                }) => (
                                                    <SelectInput
                                                        labelWidth='w-[60px]'
                                                        placeholder='Pilote'
                                                        inputRef={ref}
                                                        value={value}
                                                        error={error}
                                                        options={filterOutPilots(data.parent.producers)}
                                                        // hasNextPage={ressources?.hasNextPage}
                                                        loading={!data}
                                                        onChange={onChange}
                                                        isSearchable={true}
                                                        // fetchFunction={handleGetRessources}
                                                        labelKeys={['first_name', 'last_name']}
                                                        required={true}
                                                        disabled={disabled}
                                                        blueStyle={blueStyle}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='flex items-center'>
                                            <Controller
                                                name={`agreement_daily_rate_${index}`}
                                                control={control}
                                                rules={{required: {
                                                    value: true,
                                                    message: 'Veuillez saisir le budget'
                                                }}}
                                                render={({
                                                    field: {onChange, value},
                                                    fieldState: {error}
                                                }) => (
                                                    <TextInput
                                                        color={blueStyle ? 'blue' : 'orange'}
                                                        width='w-[100px]'
                                                        onChange={onChange}
                                                        value={value}
                                                        error={error}
                                                        required={true}
                                                        disabled={disabled}
                                                        placeholder="TJM"
                                                        type="number"
                                                        labelWidth='w-56'
                                                    />
                                                )
                                                }
                                            />
                                            <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    € HT
                                            </label>
                                        </div>
                                    </div>
                                )
                            })

                            : null}
                    </div>
                    <div className='w-full pb-4 mb-4'>
                        {partnerValues ? partnerValues.map((partner, index) => {
                            return (
                                <div key={partner.id} className="flex justify-between w-full gap-4 pb-1">
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`partner_${partner.contractor?.name ? partner.contractor?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{required: {
                                                value: true,
                                                message: 'Veuillez saisir le budget'
                                            }}}
                                            render={({
                                                field: {onChange, value, ref},
                                                fieldState: {error}
                                            }) => (
                                                <SelectInput
                                                    labelWidth='w-[60px]'
                                                    // label='Partenaire'
                                                    placeholder='Partenaire'
                                                    inputRef={ref}
                                                    value={value}
                                                    error={error}
                                                    options={filterOutAlreadySelected(contractors?.options, partnerValues)}
                                                    hasNextPage={ressources?.hasNextPage}
                                                    loading={!ressources}
                                                    onChange={onChange}
                                                    isSearchable={true}
                                                    fetchFunction={handleGetRessources}
                                                    labelKeys={['name']}
                                                    required={true}
                                                    disabled={disabled}
                                                    blueStyle={blueStyle}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`partner_budget_${partner.contractor?.name ? partner.contractor?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{required: {
                                                value: true,
                                                message: 'Veuillez saisir le budget'
                                            }}}
                                            render={({
                                                field: {onChange, value},
                                                fieldState: {error}
                                            }) => (
                                                <TextInput
                                                    color={blueStyle ? 'blue' : 'orange'}
                                                    // width='w-[100px]'
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Budget HT"
                                                    type="number"
                                                    labelWidth='w-56'
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    €
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`payment_${partner.contractor?.name ? partner.contractor?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{required: {
                                                value: true,
                                                message: 'Veuillez saisir le nombre des jours'
                                            }}}
                                            render={({
                                                field: {onChange, value},
                                                fieldState: {error}
                                            }) => (
                                                <SelectInput
                                                    // width='w-[100px]'
                                                    label=''
                                                    options={
                                                        [{id: 1, direct: true, label: 'Direct'},
                                                            {id: 2, direct: false, label: 'Indirect'}]
                                                    }
                                                    labelKeys={['label']}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Paiement"
                                                    type="number"
                                                    // labelWidth='w-40'
                                                />
                                            )}
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                        </label>
                                    </div>
                                    <div className='flex items-center'>
                                        <Controller
                                            name={`vat_rate_${partner.contractor?.name ? partner.contractor?.name : `partner_${index}`}`}
                                            control={control}
                                            rules={{required: {
                                                value: true,
                                                message: 'Veuillez saisir la tva'
                                            }}}
                                            render={({
                                                field: {onChange, value},
                                                fieldState: {error}
                                            }) => (
                                                <TextInput
                                                    color={blueStyle ? 'blue' : 'orange'}
                                                    width='w-[100px]'
                                                    label=''
                                                    onChange={onChange}
                                                    value={value}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    placeholder="Taux TVA"
                                                    type="number"
                                                />
                                            )}d
                                        />
                                        <label className="font-normal text-sm text-[#646464] pl-1 ">
                                    % TVA
                                        </label>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                    <div className={`flex justify-between  pt-2 mb-10 border-t ${partnersWithCustomer.length ? 'w-full gap-x-10' : 'w-1/2'}`}>
                        <div className="flex justify-between w-full text-[#646464]">
                            <p>Total {partnersWithCustomer.length ? 'hors co-traitants ' : ''}:</p>
                            <p>{formatThousands(totalWithoutPartners)} € HT</p>
                        </div>

                        {partnersWithCustomer.length ?
                            <div className="flex justify-between w-full text-[#646464]">
                                <p>Total:</p>
                                <p>{formatThousands(totalWithPartners)} € HT</p>
                            </div>
                            : null}
                    </div>
                </div>}
            <div className="flex flex-col justify-center pb-2 mt-2">
                <div className="flex justify-center pb-2">
                    <Button
                        onClick={() => {
                            reset();
                            setView(false);
                        }}
                        type={'secondary'}
                        content={'Annuler'}
                        disabled={disabled}
                    />
                    <Button
                        type={blueStyle ? 'blue' : 'primary'}
                        content={'Sauvegarder'}
                        onClick={handleSubmit(onSubmit)}
                        loading={loading}
                        disabled={!isValid}
                    />
                </div>
                <Error errors={errors}/>
            </div>
        </ContainerCard>
    );
}

export default BudgetPopup;
