import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ContractorActions, CustomerActions, DealActions, DealDasActions, DealOriginsActions, MissionActions, SubsidiaryActions, UserActions } from '../../../../actions';
import Button from '../../../../components/ui/Button';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import Popup from '../../../../components/ui/Popup/Popup';
import { notify } from '../../../../components/ui/Toast/Toast';
import CustomerPopup from '../../../customersPage/popups/customerPopup/CustomerPopup';
import mutatePayload from '../../functions/mutatePayload';
import { fetchSelectData, getErrors } from './../../../../utils';

import BusinessPopup from './BusinessPopup';
import InitialPopup from './InitialPopup';
import InternalPopup from './InternalPopup';

const NewDealPopup = ({ setView, userData }) => {
    const navigate = useNavigate();

    // -- states --
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [ACBC, setACBC] = useState(null);
    const [contractors, setContractors] = useState(null);
    const [dealDas, setDealDas] = useState(null);
    const [dealOrigins, setDealOrigins] = useState(null);
    const [subsidiaryCategories, setSubsidiaryCategories] = useState(null);
    const [ressources, setRessources] = useState(null);
    const [namePrefix, setNamePrefix] = useState(null);
    const [updateCustomer, setUpdateCustomer] = useState(null);

    // states de gestion de l'affichage des inputs
    const [customerType, setCustomerType] = useState(null);

    const [customerPopupView, setCustomerPopupView] = useState(false);
    // const [customerPopupView, setCustomerPopupView] = useState(false);

    const [partnerValues, setPartnerValues] = useState([
        { id: Math.random(), ressource: null, role: '' }
    ]);
    const [productionRessourcesValues, setProductionRessourcesValues] =
        useState([
            {
                id: null,
                ressource: null,
                intervention_days: 0,
                daily_rate: 0,
                budget: 0
            }
        ]);

    const [saleValues, setSaleValues] = useState([
        {
            id: Math.random(),
            percent: '',
            ressource: {
                id: userData.userId,
                username: userData.userProfileName,
                first_name: userData.name.split(' ').shift(),
                last_name: userData.name.split(' ').slice(1).join(' ')
            }
        }
    ]);

    const [pilotsValues, setPilotsValues] = useState([
        {
            id: Math.random(),
            daily_rate: '',
            budget:'',
            ressource: {
                id: userData.userId,
                username: userData.userProfileName,
                first_name: userData.name.split(' ').shift(),
                last_name: userData.name.split(' ').slice(1).join(' ')
            }
        }
    ]);

    const vat = useSelector((state) => state.GlobalsReducer.vat);

    const handleGetCustomers = async (search, page = 1) => {
        await fetchSelectData({
            action: CustomerActions.getAllCustomers,
            search: `&search=${search}`,
            page: page || null,
            setter: setCustomers,
            userData
        });
    };

    const handleGetDealDas = async (search, page = 1) => {
        await fetchSelectData({
            action: DealDasActions.getAllDealDas,
            search: `&search=${search}`,
            page: page || null,
            setter: setDealDas,
            userData
        });
    };

    const handleGetDealOrigins = async (search, page = 1) => {
        const filterOrFind = (origin) => origin.is_active;
        await fetchSelectData({
            action: DealOriginsActions.getAllDealOrigins,
            search: `&search=${search}`,
            page: page || null,
            setter: setDealOrigins,
            userData,
            filterOrFind
        });
    };

    const handleGetSubsidiaries = async (search, page = 1) => {
        await fetchSelectData({
            action: SubsidiaryActions.getAllSubsidiaries,
            search: `&search=${search}`,
            page: page || null,
            setter: setSubsidiaryCategories,
            userData,
            resultPath: 'data'
        });
    };

    const handleGetContractors = async (search, page = 1) => {
        await fetchSelectData({
            action: ContractorActions.getAllContractors,
            search: `&search=${search}`,
            page: page || null,
            setter: setContractors,
            userData,
            resultPath: 'data'
        });
    };

    const handleGetACBC = async (search, page = 1, customerId) => {
        if (!customerId) return;

        await fetchSelectData({
            action: DealActions.getAllDeals,
            search: `&search=${search}&type=framework&type=purchase_order&customer=${customerId}`,
            page: page || null,
            setter: setACBC,
            userData,
            resultPath: 'data'
        });
    };

    const handleGetRessources = async (search, page = 1) => {
        const result = await UserActions.getAllSortedUsers(
            userData,
            `&search=${search}`,
            (page = 1)
        );

        const options = result.data?.results.map((ressource) => ({
            ...ressource,
            name: ressource.first_name + ' ' + ressource.last_name
        }));

        const hasNextPage = result.data?.next ? true : false;

        if (page === 1 || !page || search) {
            setRessources({ options, hasNextPage });
        }
        else {
            setRessources((prevData) => ({
                options: [...prevData.options, ...options],
                hasNextPage
            }));
        }
    };

    let defaultValues;

    // -- react hook form --
    defaultValues = {
        short_name: '',
        das: null,
        name: '',
        production_lead: {
            username: userData.userProfileName,
            id: userData.userId,
            name: userData.name
        },
        accord_cadre: '',
        customer: null,
        subsidiary: null,
        deal_type: { id: 0, name: 'Marché', value: 'business' },
        origin: null,
        chance: null,
        status: null,
        budget: null,
        sales: {
            username: userData.userProfileName,
            id: userData.userId,
            name: userData.name
        },
        analysis_date: dayjs(),
        max_response_date: dayjs(),
        reception_date: dayjs(),
        mission_start_date: dayjs(),
        mission_end_date: dayjs(),
        response_date: dayjs(),
        min_vat_rate: vat,
        vat_rate_global: vat,
        max_vat_rate: vat

    };

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid }
    } = useForm({ defaultValues: defaultValues });

    const customerValue = watch('customer');
    const accordCadreValue = watch('accord_cadre');
    const nameValue = watch('name');

    // Get dropdows data
    useEffect(() => {
        // ↓ deal subsidiary dropdown values
        (async () => {
            await handleGetSubsidiaries('', 1);
        })();

        // ↓ customers dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();
    }, []);

    // --------------------------------------------
    // ↓ Side Effect for selecting Which form we want to show ↓
    // --------------------------------------------

    const manualySetCustomerType = () => {
        if (customerValue) {
            if (
                customerValue.name === "Terre d'avance" ||
                customerValue.name === "Terre d'avance formation"
            ) {
                setCustomerType(0);
                setValue('status', { id: 0, name: 'Interne' });
            }
            else {
                setCustomerType(1);
                setValue('status', { id: 100, name: 'CdC demandé' });
            }
        }
    };

    const refreshCustomerData = async () => {
        await handleGetCustomers('', 1);
    };

    // Submit form
    const onSubmit = async (data) => {
        setErrors(null);
        setDisabled(true);
        setLoading(true);

        const internalCheck =
            data.customer.name === "Terre d'avance" ||
            data.customer.name === "Terre d'avance formation";

        // const type = internalCheck ? "project" : data.deal_type.id;
        const subsidiary = internalCheck
            ? subsidiaryCategories.options.find(
                (el) =>
                    el.name.toLowerCase() === data.customer.name.toLowerCase()
            )?.name
            : data.subsidiary.name;
        const origin = internalCheck ? 'Indéfinie' : data.origin.name;

        const createdPayloadSales = saleValues.map((sale) => {
            if (!sale.ressource) return;

            return {
                ressource_id: sale.ressource.id,
                username: sale.ressource.username,
                percent: sale.percent ? sale.percent : 0
            };
        });

        const payload = {
            customer_id: data.customer.id,
            parent_id: data.accord_cadre.id,
            name: data.name,
            short_name: data.short_name,
            subsidiary: subsidiary,
            das: data.das.name,
            origin: origin,
            type: internalCheck ? 'project' : data.deal_type.value,
            status: data.status.id,
            sales: createdPayloadSales
        };

        // --------------------------------------------
        // ↓ Logic for mutating payload based on deal status because od new inputs ↓
        // --------------------------------------------
        const mutadedPayload = mutatePayload({
            payload,
            data,
            partnerValues,
            pilotsValues,
            internalCheck
        });

        const dealResult = await DealActions.createDeal(
            userData,
            mutadedPayload
        );

        if (!dealResult.success) {
            setErrors(getErrors(dealResult));
            setLoading(false);
            setDisabled(false);

            return;
        }

        // --------------------------------------------
        // ↓ For every Ressource added in the form create a mission ↓
        // --------------------------------------------

        if (internalCheck || dealResult.data.status >= 300) {
            const missionsResult = productionRessourcesValues.map(
                async (ressource) => {
                    if (!ressource.ressource) return;

                    const payload = {
                        deal_id: dealResult.data.id,
                        ressource_id: ressource.ressource.id,
                        billable_days: ressource.intervention_days,
                        daily_rate: ressource.daily_rate
                            ? ressource.daily_rate
                            : 0,
                        status: internalCheck ? 1 : 0
                    };

                    return await MissionActions.createMission(
                        userData,
                        payload
                    );
                }
            );

            const result = await Promise.all(missionsResult);

            console.log('Create Mission Result', result);

        }

        setLoading(false);
        setDisabled(false);
        setView(false);
        navigate(`/deals/${dealResult.data.id}/informations`);
        notify("L'affaire à bien été créé");
    };

    return (
        <ContainerCard
            title={customerType === null ? 'Nouvelle Affaire' : `${nameValue}`}
            overflow='overflow-clip'
            coloredButtonDisplayed={false}
        >
            <div className='flex items-center justify-between px-4 pt-2 pb-6 gap-y-3'>
                {customerType === null ? (
                    <InitialPopup
                        control={control}
                        customers={customers}
                        handleGetACBC={handleGetACBC}
                        ACBC={ACBC}
                        handleGetCustomers={handleGetCustomers}
                        setCustomerPopupView={setCustomerPopupView}
                        // setNamePrefix={setNamePrefix}
                        disabled={disabled}
                        customerValue={customerValue}
                        // accordCadreValue={accordCadreValue}
                        // setCustomerType={setCustomerType}
                        // setValue={setValue}
                    />
                ) : null}
                {customerType === 0 ? (
                    <InternalPopup
                        setValue={setValue}
                        namePrefix={namePrefix}
                        control={control}
                        watch={watch}
                        disabled={disabled}
                        handleGetCustomers={handleGetCustomers}
                        handleGetDealDas={handleGetDealDas}
                        handleGetRessources={handleGetRessources}
                        productionRessourcesValues={productionRessourcesValues}
                        setProductionRessourcesValues={
                            setProductionRessourcesValues
                        }
                        customers={customers}
                        dealDas={dealDas}
                        ressources={ressources}
                        errors={errors}
                    />
                ) : null}
                {customerType === 1 ? (
                    <BusinessPopup
                        userData={userData}
                        setValue={setValue}
                        namePrefix={namePrefix}
                        handleGetSubsidiaries={handleGetSubsidiaries}
                        handleGetCustomers={handleGetCustomers}
                        handleGetDealOrigins={handleGetDealOrigins}
                        handleGetDealDas={handleGetDealDas}
                        handleGetRessources={handleGetRessources}
                        handleGetContractors={handleGetContractors}
                        partnerValues={partnerValues}
                        setPartnerValues={setPartnerValues}
                        saleValues={saleValues}
                        setSaleValues={setSaleValues}
                        productionRessourcesValues={productionRessourcesValues}
                        setProductionRessourcesValues={setProductionRessourcesValues}
                        pilotsValues={pilotsValues}
                        setPilotsValues={setPilotsValues}
                        contractors={contractors}
                        subsidiaryCategories={subsidiaryCategories}
                        customers={customers}
                        dealOrigins={dealOrigins}
                        dealDas={dealDas}
                        ressources={ressources}
                        setCustomerType={setCustomerType}
                        customerValue={customerValue}
                        accordCadreValue={accordCadreValue}
                        watch={watch}
                        control={control}
                    />
                ) : null}
            </div>
            <div className='flex flex-col justify-center pb-2 mt-2'>
                {customerType !== null ? (
                    <div className='flex justify-center pb-2'>
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content={'Créer l’affaire'}
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                ) : null}
                {customerType === null ? (
                    <div className='flex justify-center pb-2'>
                        <Button
                            onClick={() => setView(false)}
                            type={'secondary'}
                            content={'Annuler'}
                            disabled={disabled}
                        />
                        <Button
                            type={'primary'}
                            content={'Suivant'}
                            onClick={manualySetCustomerType}
                            loading={loading}
                            disabled={!isValid}
                        />
                    </div>
                ) : null}
                <Error errors={errors}/>
            </div>
            <Popup
                view={customerPopupView}
                setView={setCustomerPopupView}
                width={'1150px'}
                yOverflow=''
                xOverflow=''
            >
                <CustomerPopup
                    userData={userData}
                    updateCustomer={updateCustomer}
                    refreshdata={refreshCustomerData}
                    setView={setCustomerPopupView}
                    setValue={setValue}
                />
            </Popup>
        </ContainerCard>
    );
};

export default NewDealPopup;
