import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Tooltip from 'antd/lib/tooltip';
import deleteIcon from '../../../../assets/icons/actionsbutton/delete.png';
import deleteIconBlue from '../../../../assets/icons/blueStyle/delete-blue.png';
import DateInput from '../../../../components/ui/Inputs/DateInput/DateInput';
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../components/ui/Inputs/TextInput';
import { frenchFormat } from './../../../../utils';
import BudgetAndProductionModule from './PopupModules/businessModules/BudgetAndProductionModule';
import ResponseGroupModule from './PopupModules/businessModules/ResponseGroupModule';
// --------------------------------------------
// ↓ Components import ↓
// --------------------------------------------
import Popup from '../../../../components/ui/Popup/Popup';
import NewContractorPopup from '../../../contractorsPage/components/popups/NewContractorPopup';
// --------------------------------------------
// ↓ Funciton Body ↓
// --------------------------------------------

const BusinessPopup = ({
    userData,
    namePrefix,
    subsidiaryCategories,
    handleGetContractors,
    contractors,
    handleGetSubsidiaries,
    handleGetCustomers,
    handleGetDealDas,
    handleGetDealOrigins,
    handleGetRessources,
    partnerValues,
    setPartnerValues,
    saleValues,
    setSaleValues,
    productionRessourcesValues,
    setProductionRessourcesValues,
    pilotsValues,
    setPilotsValues,
    ressources,
    dealDas,
    dealOrigins,
    accordCadreValue,
    watch,
    control,
    setValue
}) => {

    // -- states --
    const [disabled, setDisabled] = useState(false);
    // const [loading, setLoading] = useState(false);
    // const [errors, setErrors] = useState(null);

    // states de gestion de l'affichage des inputs
    const [dealStatus, setDealStatus] = useState(null);

    const [newPartnerView, setNewPartnerView] = useState(false);

    // --------------------------------------------
    // Temporary
    const blueStyle = false;

    // Get dropdows data
    useEffect(() => {

        // ↓ ressources dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();
    }, []);

    useEffect(() => {
        if (namePrefix) {
            setValue('short_name', namePrefix);
        }
    }, [])

    // --------------------------------------------
    // ↓ Comment Here ↓ Functions for handling dynamic inputs
    // --------------------------------------------

    const handleAddSale = () => {
        if (saleValues.length < 10) {
            setSaleValues((prevState) => [...prevState, { id: Math.random(), percent: '', ressource: null }]);
        }
    }

    const handleRemoveSale = (id) => {
        setSaleValues((prevState) => prevState.filter((element) => element.id !== id));
    }

    const handleSaleValueChange = (e, id, type) => {
        setSaleValues((prevState) => prevState.map((el) => el.id === id ? { ...el, [type]: e } : el));
    }

    // -- static dropdown values --
    const statusData = [
        { id: 100, name: 'Cahier des charges demandé' },
        { id: 150, name: 'Étude du Cahier des charges' },
        { id: 200, name: 'Réponse en cours' }
        // { id: 300, name: 'Offre remise' },
        // { id: 500, name: 'Gagnée' }
    ];

    const dealTypeData = [
        { id: 0, name: 'Marché', value: "business" },
        { id: 1, name: 'Accord-Cadre', value: "framework" },
        { id: 2, name: 'Marché à bond de commande', value: "purchase_order" }
    ]

    const successChanceData = [
        { id: 0, name: 'Inconnue' },
        { id: 1, name: 'Faible' },
        { id: 2, name: 'Moyenne' },
        { id: 3, name: 'Forte' }
    ];

    const statusValue = watch('status');

    const subsidiaryValue = watch('subsidiary');

    // Get dropdows data
    useEffect(() => {

        // ↓ deal subsidiary dropdown values
        (async () => {
            await handleGetSubsidiaries('', 1);
        })();

        // ↓ customers dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

        // ↓ deal das dropdown values
        (async () => {
            await handleGetDealDas('', 1);
        })();

        // ↓ deal origins dropdown values
        (async () => {
            await handleGetDealOrigins('', 1);
        })();

        // ↓ ressources dropdown values
        (async () => {
            await handleGetRessources('', 1);
        })();

        // ↓ ressources dropdown values
        (async () => {
            await handleGetContractors('', 1);
        })();

    }, []);

    useEffect(() => {
        setDealStatus(statusValue ? statusValue.id : null);
    }, [statusValue])

    const filterOutAlreadySelected = (options, selected) => {

        if (!options) return;

        const filteredArray = options.filter(obj1 =>
            !selected.some(obj2 =>
                obj1?.name === obj2?.ressource?.name
            )
        );

        return filteredArray;
    };

    useEffect(() => {
        if (accordCadreValue) {
            setValue('deal_type', dealTypeData[0]);
        }

        if (dealOrigins?.options.find((origin) => origin.name === 'Juris-Marchés')) {
            setValue('origin', dealOrigins.options.find((origin) => origin.name === 'Juris-Marchés'));
        }

        if (subsidiaryCategories?.options.length) {
            setValue('subsidiary', subsidiaryCategories.options.find((subsidiary) => subsidiary.name === "Terre d'Avance"));
        }

    }, [dealOrigins])

    return (
        <div>
            <div className="flex flex-col items-center w-full px-4 pb-2 mb-6">
                <div className="flex justify-start text-gradient w-[110%] pl-2 pb-2 mb-6 border-b border-gray-200">
                    Informations du chantier
                </div>
                {/* {
                    <div className="flex w-full pb-3 border-b border-gray-200">
                        <Controller
                            name="short_name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez saisir le libellé complet'
                                }
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error }
                            }) => (
                                <TextInput
                                    width='w-[810px]'
                                    label='Libellé complet'
                                    onChange={onChange}
                                    value={value}
                                    error={error}
                                    required={true}
                                    disabled={disabled}
                                    placeholder="Libellé complet"
                                    type="text"
                                    labelWidth='w-40'
                                />
                            )}
                        />
                    </div>
                } */}
                <div className='w-full border-b border-gray-200'>
                    <div className='flex justify-between pb-2'>
                        <Controller
                            name="subsidiary"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez saisir la chance de réussite'
                                }
                            }}
                            render={({
                                field: { onChange, ref, value },
                                fieldState: { error }
                            }) => (
                                <SelectInput
                                    label='Filiale'
                                    placeholder='Filiale'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={subsidiaryCategories?.options}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    labelWidth='w-40'
                                />
                            )}
                        />
                        <Controller
                            name="das"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez saisir le nom domaine d’activité'
                                }
                            }}
                            render={({
                                field: { onChange, ref, value },
                                fieldState: { error }
                            }) => (
                                <SelectInput
                                    label='DAS'
                                    placeholder='Domaine d’activité'
                                    inputRef={ref}
                                    value={value}
                                    error={error}
                                    options={dealDas?.options}
                                    hasNextPage={dealDas?.hasNextPage}
                                    onChange={onChange}
                                    fetchFunction={handleGetDealDas}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    labelWidth='w-40'
                                    loading={!dealDas}
                                />
                            )}
                        />
                    </div>
                    <div className='flex justify-between pb-4'>
                        <Controller
                            name="deal_type"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez saisir un statut'
                                }
                            }}
                            render={({
                                field: { onChange, ref, value },
                                fieldState: { error }
                            }) => (
                                <SelectInput
                                    label="Type d'affaire"
                                    placeholder="Type d'affaire"
                                    inputRef={ref}
                                    error={error}
                                    value={accordCadreValue ? dealTypeData[0] : value}
                                    // value={value}
                                    options={dealTypeData}
                                    onChange={onChange}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={accordCadreValue ? true : disabled}
                                    labelWidth='w-40'
                                />
                            )}
                        />
                        <Controller
                            name="origin"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Veuillez saisir l\'origine'
                                }
                            }}
                            render={({
                                field: { onChange, ref, value },
                                fieldState: { error }
                            }) => (
                                <SelectInput
                                    label='Origine'
                                    placeholder='Origine'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    options={dealOrigins?.options}
                                    hasNextPage={dealOrigins?.hasNextPage}
                                    onChange={onChange}
                                    fetchFunction={handleGetDealOrigins}
                                    labelKeys={['name']}
                                    required={true}
                                    disabled={disabled}
                                    isSearchable={true}
                                    labelWidth='w-40'
                                    loading={!dealOrigins}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex justify-start pl-2 text-gradient w-[110%] pb-2 pt-4 mb-6 border-b border-gray-200">
                    Statut & Équipe Commerciale
                </div>
                {
                    <div className="w-full pb-3 border-b border-gray-200">
                        <div className='flex w-full pb-2'>
                            <Controller
                                name="status"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Veuillez saisir un statut'
                                    }
                                }}
                                render={({
                                    field: { onChange, ref, value },
                                    fieldState: { error }
                                }) => (
                                    <SelectInput
                                        label='Statut'
                                        width='w-[810px]'
                                        placeholder='Statut'
                                        inputRef={ref}
                                        error={error}
                                        value={value}
                                        options={statusData}
                                        onChange={onChange}
                                        labelKeys={['name']}
                                        required={true}
                                        disabled={disabled}
                                        labelWidth='w-40'
                                    />
                                )}
                            />
                        </div>
                        <div className='w-full'>
                            {
                                saleValues.map((sale, index) => {
                                    return (
                                        <div
                                            className='flex w-full'
                                            key={sale.id}
                                        >
                                            <div className='w-full flex flex-row justify-between  mb-1.5'>
                                                <div className='flex w-full'>
                                                    <SelectInput
                                                        label="Commercial"
                                                        placeholder='Choissisez un commercial'
                                                        width={
                                                            index === 0 && dealStatus < 300 ? 'w-[810px]' :
                                                                index === 0 && dealStatus >= 300 ? "w-[735px]" :
                                                                    index !== 0 && dealStatus < 300 ? "w-[780px]" : 'w-[680px]'}
                                                        value={sale.ressource}
                                                        options={filterOutAlreadySelected(ressources?.options, saleValues)}
                                                        onChange={(e) => handleSaleValueChange(e, sale.id, 'ressource')}
                                                        labelKeys={['first_name', 'last_name']}
                                                        disabled={disabled}
                                                        fetchFunction={handleGetRessources}
                                                        hasNextPage={ressources?.hasNextPage}
                                                        isSearchable={true}
                                                        loading={!ressources}
                                                        labelWidth='w-40'
                                                    />
                                                    {dealStatus >= 300 ?
                                                        <div className="flex ml-3">
                                                            <TextInput
                                                                label=''
                                                                onChange={(e) => handleSaleValueChange(e, sale.id, 'percent')}
                                                                value={sale.percent}
                                                                error={null}
                                                                required={true}
                                                                disabled={disabled}
                                                                placeholder="%"
                                                                type="number"
                                                                width='w-16'
                                                            />
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className='flex justify-between'>
                                                    {
                                                        index === 0 ?
                                                            null
                                                            : <button
                                                                onClick={() => handleRemoveSale(sale.id)}
                                                                disabled={disabled}
                                                                className="flex items-center justify-center bg-transparent"
                                                            >
                                                                <Tooltip title='Supprimer'>
                                                                    <div>
                                                                        <img src={blueStyle ? deleteIconBlue : deleteIcon} alt="logo" className="w-[18px]"/>
                                                                    </div>
                                                                </Tooltip>
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <div className='flex items-center w-full mb-6'>
                                <Tooltip title='Ajouter une ressource'>
                                    <button
                                        disabled={!(saleValues.length < 10)}
                                        onClick={handleAddSale}
                                        className={`whitespace-nowrap cursor-pointer text-sm text-gradient ${saleValues.length < 10 ? "text-[#646464]" : "text-[#b2bec3]"}`}
                                    >
                                        <p className='hover:underline'>Ajouter une ressource</p>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            className='flex justify-between w-full'
                        >
                            {
                                dealStatus !== 100 ?
                                    <div
                                        className='pb-3'
                                    >
                                        <Controller
                                            name="reception_date"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Veuillez selectionner une date'
                                                }
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error }
                                            }) => (
                                                <DateInput
                                                    label={'Date réception CdC'}
                                                    // width='w-[810px]'
                                                    onChange={onChange}
                                                    value={frenchFormat(value)}
                                                    error={error}
                                                    required={true}
                                                    disabled={disabled}
                                                    labelWidth='w-40'
                                                />
                                            )}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                dealStatus !== 100 ?
                                    <Controller
                                        name="analysis_date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Veuillez selectionner une date'
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <DateInput
                                                label={"Date limite d'analyse"}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={disabled}
                                                labelWidth='w-40'
                                            />
                                        )}
                                    />
                                    : null
                            }
                        </div>
                        <div className={`flex ${dealStatus === 200 ? "justify-start" : "justify-between"} pb-3`}>
                            {
                                dealStatus >= 200 ?
                                    <Controller
                                        name="max_response_date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Veuillez selectionner une date'
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <DateInput
                                                label={'Date limite de réponse'}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={disabled}
                                                labelWidth='w-40'
                                            />
                                        )}
                                    />
                                    : null
                            }

                            {/* {
                                dealStatus >= 300 ?
                                    <Controller
                                        name="response_date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Veuillez selectionner une date'
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <DateInput
                                                label={'Date de réponse'}
                                                onChange={onChange}
                                                value={frenchFormat(value)}
                                                error={error}
                                                required={true}
                                                disabled={disabled}
                                                labelWidth='w-40'
                                            />
                                        )}
                                    />
                                    : null
                            } */}
                        </div>
                        {/* <div className={`flex ${dealStatus === 200 ? "justify-start" : "justify-between"}`}>
                            {
                                dealStatus >= 300 ?
                                    <Controller
                                        name="chance"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Veuillez saisir la chance de réussite'
                                            }
                                        }}
                                        render={({
                                            field: { onChange, ref, value },
                                            fieldState: { error }
                                        }) => (
                                            <SelectInput
                                                label='Espérance de réussite'
                                                placeholder='Espérance de réussite'
                                                inputRef={ref}
                                                error={error}
                                                value={value}
                                                options={successChanceData}
                                                onChange={onChange}
                                                labelKeys={['name']}
                                                required={true}
                                                disabled={disabled}
                                                labelWidth='w-40'
                                            />
                                        )}
                                    />
                                    : null
                            }
                        </div> */}
                    </div>
                }
                {/* {
                    dealStatus >= 300 ?
                        <>
                            <ResponseGroupModule
                                control={control}
                                disabled={disabled}
                                watch={watch}
                                partnerValues={partnerValues}
                                setPartnerValues={setPartnerValues}
                                setNewPartnerView={setNewPartnerView}
                                subsidiaryValue={subsidiaryValue}
                                handleGetRessources={handleGetRessources}
                                handleGetContractors={handleGetContractors}
                                contractors={contractors}
                                ressources={ressources}
                                blueStyle={blueStyle}
                            />
                            <BudgetAndProductionModule
                                control={control}
                                disabled={disabled}
                                watch={watch}
                                partnerValues={partnerValues}
                                productionRessourcesValues={productionRessourcesValues}
                                pilotsValues={pilotsValues}
                                setPilotsValues={setPilotsValues}
                                setProductionRessourcesValues={setProductionRessourcesValues}
                                ressources={ressources}
                                handleGetRessources={handleGetRessources}
                                blueStyle={blueStyle}
                                accordCadreValue={accordCadreValue}
                                setValue={setValue}
                            />
                        </>
                        : null
                } */}
            </div>
            <Popup
                view={newPartnerView}
                setView={setNewPartnerView}
                width={'1098px'}
                xOverflow=''
                yOverflow='overflow-y-auto'
                maxHeight='45rem'
            >
                <NewContractorPopup
                    setView={setNewPartnerView}
                    userData={userData}
                    toBeUpdated={null}
                    refreshData={handleGetContractors}
                />
            </Popup>
        </div>

    );
}

export default BusinessPopup;
