import React from 'react'
import {Controller} from 'react-hook-form'
import SelectInput from '../../../../components/ui/Inputs/SelectInput/SelectInput'
import TextInput from '../../../../components/ui/Inputs/TextInput'
import Tooltip from 'antd/lib/tooltip';
import {useEffect} from 'react'
import plus from '../../../../assets/icons/button/plus.png'

function InitialPopup({
    control,
    customers,
    handleGetACBC,
    ACBC,
    handleGetCustomers,
    setCustomerPopupView,
    // setNamePrefix,
    disabled,
    customerValue,
    // accordCadreValue,
    blueStyle = false
    // setValue

}) {

    // Get dropdows data
    useEffect(() => {

        // ↓ customers dropdown values
        (async () => {
            await handleGetCustomers('', 1);
        })();

    }, []);

    useEffect(() => {
        if (!customerValue?.id) return;
        // ↓ customers dropdown values

        (async () => {
            await handleGetACBC('', 1, customerValue.id);
        })();
    }, [customerValue])

    // --------------------------------------------
    // ↓ Pre-fill the client and accord cadre in title ↓
    // --------------------------------------------

    // useEffect(() => {
    //     if (customerValue && customerValue?.name !== "Terre d'avance" && customerValue && customerValue?.name !== "Terre d'avance formation" && accordCadreValue) {
    //         setValue("name", `[${customerValue.name}](${accordCadreValue.name})`);
    //         setNamePrefix(`[${customerValue.name}](${accordCadreValue.name})`);
    //     }

    //     if (customerValue && customerValue?.name !== "Terre d'avance" && customerValue && customerValue?.name !== "Terre d'avance formation" && !accordCadreValue) {
    //         setValue("name", `[${customerValue.name}]`);
    //         setNamePrefix(`[${customerValue.name}]`);
    //     }

    //     if (customerValue && customerValue?.name === "Terre d'avance" || customerValue && customerValue?.name === "Terre d'avance formation") {
    //         setValue("name", `[${customerValue.name}]`);
    //         setNamePrefix(`[${customerValue.name}]`);
    //     }
    // }, [customerValue, accordCadreValue]);

    return (
        <div className="flex flex-col items-center w-full px-4 pt-2 pb-6">
            <div className='flex'>
                <div className='relative pb-3'>
                    <Controller
                        name="customer"
                        control={control}
                        rules={{required: {
                            value: true,
                            message: 'Veuillez saisir un client'
                        }}}
                        render={({
                            field: {onChange, ref, value},
                            fieldState: {error}
                        }) => (
                            <SelectInput
                                width='w-[800px]'
                                label='Client'
                                placeholder='Client'
                                inputRef={ref}
                                error={error}
                                value={value}
                                options={customers?.options}
                                hasNextPage={customers?.hasNextPage}
                                onChange={onChange}
                                fetchFunction={handleGetCustomers}
                                labelKeys={['name']}
                                required={true}
                                disabled={disabled}
                                isSearchable={true}
                                loading={!customers}
                                labelWidth='w-40'
                            />
                        )}
                    />
                    <div className='flex items-center justify-center pb-3 absolute right-[-35px] top-[2px]'>
                        <Tooltip title='Ajouter un client'>
                            <button
                                onClick={() => setCustomerPopupView(true)}
                                disabled={disabled}
                                className={`hover:opacity-90 p-2 rounded-full ${blueStyle ? 'background-gradient-blue' : 'background-gradient'}`}
                            >
                                <img src={plus} alt='plus' className='w-3'/>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            {
                customerValue && customerValue.name !== "Terre d'avance" && customerValue && customerValue.name !== "Terre d'avance formation" && ACBC?.options.length ?
                    <div className='pb-3'>
                        <Controller
                            name="accord_cadre"
                            control={control}
                            render={({
                                field: {onChange, ref, value},
                                fieldState: {error}
                            }) => (
                                <SelectInput
                                    width='w-[800px]'
                                    label='Accord cadre/ BC'
                                    placeholder='Accord cadre/BC'
                                    inputRef={ref}
                                    error={error}
                                    value={value}
                                    isClearable={true}
                                    options={ACBC?.options}
                                    hasNextPage={ACBC?.hasNextPage}
                                    onChange={onChange}
                                    fetchFunction={handleGetACBC}
                                    labelKeys={['name']}
                                    disabled={disabled}
                                    isSearchable={true}
                                    loading={!ACBC}
                                    labelWidth='w-40'
                                />
                            )}
                        />
                    </div> : null
            }
            <div className='pb-3'>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: {
                        value: true,
                        message: 'Veuillez saisir le nom abrégé'
                    }}}
                    render={({
                        field: {onChange, value},
                        fieldState: {error}
                    }) => (
                        <TextInput
                            width='w-[800px]'
                            label='Nom abrégé'
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Affaire"
                            type="text"
                            labelWidth='w-40'
                        />
                    )}
                />
            </div>

            <div className="pb-3">
                <Controller
                    name="short_name"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: 'Veuillez saisir le libellé complet'
                        }
                    }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <TextInput
                            width='w-[800px]'
                            label="Libellé complet de l'affaire"
                            onChange={onChange}
                            value={value}
                            error={error}
                            required={true}
                            disabled={disabled}
                            placeholder="Libellé complet de l'affaire"
                            type="text"
                            labelWidth='w-40'
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default InitialPopup