import React, { useEffect, useState } from 'react';

import { MissionActions } from '../../../../actions';
import Badge from '../../../../components/ui/Badge';
import ContainerCard from '../../../../components/ui/ContainerCard';
import Error from '../../../../components/ui/Error/Error';
import Popup from '../../../../components/ui/Popup/Popup';
import { frenchFormat } from '../../../../utils';
import { aggregateBudgetObject } from '../../functions/aggregateBudgetObject';
import BudgetPopup from '../../popups/dealDetailPopups/BudgetPopup';
import ContractorsPopup from '../../popups/dealDetailPopups/ContractorePopup';
import DealInformationsPopup from '../../popups/dealDetailPopups/DealInformationsPopup';
import ProductionInternalPopup from "../../popups/dealDetailPopups/ProductionInternalPopup";
import ProductionPopup from '../../popups/dealDetailPopups/ProductionPopup';
import ResponseGroupPopup from '../../popups/dealDetailPopups/ResponseGroupPopup';
import SalesPopup from '../../popups/dealDetailPopups/SalesPopup';
import { formatThousands } from '../../../../utils/numberFormatting';

const InformationsSection = ({
    data,
    refreshData,
    userData,
    salesCardVisibility,
    responseGroupVisibility,
    productionCardVisibility,
    budgetCardVisibility,
    cannotBeModified,
    blueStyle
}) => {
    const [dealInformationsPopupView, setDealInformationsPopupView] = useState(false);
    const [budgetTableEdit, setBudgetTableEdit] = useState(false);
    const [budgetTableData, setBudgetTableData] = useState([]);
    const [contractorsPopupView, setContractorsPopupView] = useState(false);
    const [newContractorPopupView, setNewContractorPopupView] = useState(false);
    const [salesPopupView, setSalesPopupView] = useState(false);
    const [productionPopupView, setProductionPopupView] = useState(false);
    const [productionInternalPopupView, setProductionInternalPopupView] = useState(false);
    const [responseGroupPopupView, setResponseGroupPopupView] = useState(false);
    const [budgetPopupView, setBudgetPopupView] = useState(false);
    const [salesPopupOverflow, setSalesPopupOverflow] = useState('');
    const [errors, setErrors] = useState(null);

    const [totalInterventionDays, setTotalInterventionDays] = useState(0);
    const [totalBudget, setTotalBudget] = useState(0);

    const [productionRessourcesValues, setProductionRessourcesValues] = useState([]);

    const [partnerValues, setPartnerValues ] = useState(
        [{
            id: Math.random(),
            ressource: null,
            role: {id: 1, name: 'Cotraitant'}
        }]
    );

    useEffect(() => {
        const budgetData = Object.keys(data).length > 0 ? aggregateBudgetObject(data) : [];

        setBudgetTableData(budgetData);
    }, [data]);

    useEffect(() => {
        (async () => {
            try {
                if (!data.producers) return;
                // const paramString;
                const missionData = data.producers.map(async (producer) => {
                    const result = await MissionActions.getAllMissions(userData, `&ressource=${producer.username}&deal=${data.id}`, 1);

                    return result.data[0];
                })

                const missionDataResolve = await Promise.all(missionData);

                setProductionRessourcesValues(missionDataResolve)
            }
            catch (err) {
                console.error(err);
            }
        })();
    }, [data]);

    // --------------------------------------------
    // ↓ Dynamically calculate totals for Production Section ↓
    // --------------------------------------------
    useEffect(() => {
        if (!productionRessourcesValues) return;

        const newTotalInterventionDays = productionRessourcesValues.reduce((acc, curr) => {
            const totalInterventionDays = acc + parseInt(curr.billable_days ? curr.billable_days : 0);

            return totalInterventionDays;
        }, 0);

        const newTotalBudget = productionRessourcesValues.reduce((acc, curr) => {
            const totaldailyRate = acc + parseInt(curr.daily_rate) * parseInt(curr.billable_days);

            return totaldailyRate;
        }, 0);

        setTotalInterventionDays(newTotalInterventionDays);
        setTotalBudget(newTotalBudget);

    }, [productionRessourcesValues])

    // const budgetTableConfig = dealBudgetTableConfig({budgetTableEdit, setContractorsPopupView, cannotBeModified, blueStyle});

    // const onSaveFunction = async (dataArray) => {
    //     setErrors(null);

    //     const payload = {
    //         budget: dataArray[0]?.facturable ? dataArray[0].facturable : data.facturable,
    //         commission: dataArray[2]?.facturable ? dataArray[2].facturable : data.commission,
    //         billable_days: dataArray[0]?.jours_h ? dataArray[0].jours_h : data.billable_days
    //     }

    //     const updateResult =  await DealActions.updateDeal(userData, payload, data.id );

    //     if (updateResult.success) {
    //         await refreshData();
    //         setBudgetTableEdit(false);
    //         setSubmitButtonLoading(false);
    //         notify('Budget de l’affaire mis à jour');
    //     }
    //     else {
    //         setSubmitButtonLoading(false);
    //         setErrors(getErrors(updateResult));
    //     }
    // }

    useEffect(() => {
        if (!budgetTableEdit) {
            setErrors(null);
        }
    }, [budgetTableEdit])

    useEffect(() => {

        if (!data.contractors || !data?.contractors?.length) return;

        setPartnerValues(data.contractors.map((contractor) => {
            return {
                id: Math.random(),
                ressource: contractor,
                role: contractor.is_cocontractor ? {id:1,  name:'Cotraitant'} : {id: 2, name: 'Mandataire'}
            }
        }))

    }, [responseGroupPopupView, data])

    // badge colours management
    const chanceColor = (chance) => {
        switch (chance) {
            case 0:
                return {name: 'Inconnue', color: '#646464'};
            case 1:
                return {name: 'Faible', color: '#f0a841'};
            case 2:
                return {name: 'Moyenne', color: '#e36d38'};
            case 3:
                return {name: 'Forte', color: '#dc3832'};
            default:
                return {name: 'Inconnue', color: ''};
        }
    }

    const getType = (type) => {
        switch (type) {
            case 'business':
                return 'Marché';
            case 'project':
                return 'Chantiers internes';
            case 'purchase_order' :
                return 'Bond de commande';
            case 'framework':
                return 'Accord Cadre';
            default:
                return '-';
        }
    }

    const renderTaStatus = () => {
        return !data.contractors.length ? "Seul" : data.contractors.every((contractor) => contractor.is_cocontractor) ? "Mandataire" : "Cotraitant";
    }

    const isInternal = data?.customer?.name === "Terre d'avance" || data?.customer?.name === "Terre d'avance formation"

    // -- total without budget --
    let totalWithoutPartners = (parseInt(data.budget) || 0) +  (parseInt(data.expense_budget) || 0) + (parseInt(data.commission) || 0) + (parseInt(data.procurement_cost) || 0);

    // -- Add pilots budget --
    if (data.agreement_leads) {
        data.agreement_leads.forEach((pilot) => {
            totalWithoutPartners += parseInt(pilot.budget) || 0;
        })
    }

    let totalWithPartners = totalWithoutPartners;

    const partnerValuesWithRessource = partnerValues.filter((partner) => partner?.ressource);

    // -- Add partners budget --
    if (partnerValuesWithRessource.length) {
        partnerValuesWithRessource.forEach((partner, index) => {
            if(partner?.ressource?.direct) return;

            totalWithPartners += parseInt(partner?.ressource?.budget) || 0;
        })
    }

    // Titre de la section production
    const productionTitle = data.type === 'purchase_order' || data.type === 'framework' ? 'Pilotage' : 'Production';

    return (
        <>
            <ContainerCard
                title='Informations de l’affaire'
                editButtonDisplayed={!cannotBeModified}
                onEditButtonClick={() => setDealInformationsPopupView(true)}
                coloredButtonDisplayed={false}
                blueStyle={blueStyle}
            >
                <div className='flex flex-col'>
                    {
                        <>
                            <div className='flex items-center justify-between mb-2'>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>{"Libellé de l'affaire:"}</div>
                                    <div className='text-[#212121] text-base'>{data.short_name}</div>
                                </div>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Type:</div>
                                    <div className='text-[#212121] text-base'>{getType(data.type)}</div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between my-2'>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Client:</div>
                                    <div className='text-[#212121] text-base'>{data.customer?.name}</div>
                                </div>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Origine:</div>
                                    <div className='text-[#212121] text-base'>{data.origin}</div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between my-2'>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Domaine d’activité:</div>
                                    <div className='text-[#212121] text-base'>
                                        <Badge content={data.das} color={data.das === 'Indéfini' ? '#646464' : null} blueStyle={data.das === 'Formation'}/>
                                    </div>
                                </div>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Filliale:</div>
                                    <div className='text-[#212121] text-base'>{data.subsidiary}</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </ContainerCard>
            {
                data.type === "project" ?
                    <div className='mt-5 mb-5'>
                        <ContainerCard
                            title='Ressources'
                            editButtonDisplayed={!cannotBeModified}
                            onEditButtonClick={() => setProductionInternalPopupView(true)}
                            width='w-full'
                            coloredButtonDisplayed={false}
                            blueStyle={blueStyle}
                        >
                            <div className='flex flex-col'>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Responsable du chantier</div>
                                        <div className='text-[#212121] text-base'>
                                            {
                                                data.production_lead ?
                                                    <Badge type='round' content={[data.production_lead]}/> :
                                                    "-"
                                            }
                                        </div>
                                    </div>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Ensemble des producteurs</div>
                                        <div className='text-[#212121] text-base'>
                                            {
                                                data?.producers?.length ?
                                                    <Badge type='round' content={data?.producers}/> :
                                                    "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr className='w-12 my-5 border-[#212121]'/>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Début du chantier</div>
                                        <div className='text-[#212121] text-base'>{data.mission_start_date ? frenchFormat(data.mission_start_date) : "-"}</div>
                                    </div>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Fin de chantier</div>
                                        <div className='text-[#212121] text-base'>{data.mission_end_date ? frenchFormat(data.mission_end_date) : "-"}</div>
                                    </div>
                                </div>
                            </div>
                            <hr className='w-12 my-5 border-[#212121]'/>
                            <div className='pb-2'>
                                {productionRessourcesValues.length > 0 && productionRessourcesValues.map((producer, index) => {
                                    return (
                                        <div key={index} className='flex items-center justify-start '>
                                            <div className='flex items-start justify-start mb-2'>
                                                <div className='text-[#212121] text-base'>
                                                    <Badge type='round' content={[producer.ressource]}/>
                                                </div>
                                            </div>
                                            <div className='flex w-[200px] items-start justify-end mb-2 ml-10'>
                                                <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Jours d'intervention:"}</div>
                                                <div className='text-[#212121] text-base'>
                                                    {producer.billable_days ? producer.billable_days : 0}
                                                </div>
                                            </div>
                                            {index === 0 ? <div className='flex items-start justify-end mb-2 ml-10'>
                                                <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Dépenses prévues:"}</div>
                                                <div className='text-[#212121] text-base'>
                                                    {data.budget}€ HT
                                                </div>
                                            </div> : null}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex items-center justify-start mb-2 border-t border-gray-200 w-[800px] pt-2'>
                                <div className="font-normal text-m text-[#646464]">
                                                    TOTAL:
                                </div>
                                <div className="font-normal text-m text-[#646464] ml-[20.5px] w-[200px] flex justify-end">
                                    Jours: {parseInt(totalInterventionDays.toFixed(2))}
                                    {/* {"Jours: 31"} */}
                                </div>
                            </div>
                        </ContainerCard>
                    </div>
                    : null
            }
            {
                data.status === 100 && data.type !== "project" ?
                    <ContainerCard
                        title={'Commercial'}
                        editButtonDisplayed={!cannotBeModified}
                        onEditButtonClick={() => setSalesPopupView(true)}
                        width={'w-full mt-6'}
                        coloredButtonDisplayed={false}
                        blueStyle={blueStyle}
                    >
                        <div className='flex flex-col'>
                            <div className='flex items-start justify-start mb-3'>
                                <div className='text-[#646464] text-sm mt-[3px] mr-4'>{"Équipe commerciale:"}</div>
                                <Badge
                                    type='round'
                                    content={data?.sales}
                                />
                            </div>
                            <div className='flex items-start justify-start mb-3'>
                                <div className='text-[#646464] text-sm mt-[3px] mr-4'>Date de réception CdC:</div>
                                <div className='text-[#212121] text-base'>{data.reception_date ? frenchFormat(data.reception_date) : "-"}</div>
                            </div>
                        </div>
                    </ContainerCard>
                    : null
            }
            {
                salesCardVisibility ?
                    <div className='mt-5 mb-5'>
                        <ContainerCard
                            title={'Commercial'}
                            editButtonDisplayed={!cannotBeModified}
                            onEditButtonClick={() => setSalesPopupView(true)}
                            width='w-full'
                            coloredButtonDisplayed={false}
                            blueStyle={blueStyle}
                        >
                            <div className='flex flex-col'>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>
                                        {"Resp. Commerciaux"}
                                    </div>
                                    <div className='flex flex-row items-center justify-center'>
                                        <Badge
                                            type='round'
                                            content={data?.sales}
                                            topBadge={ data.status >= 300 ? true : false}
                                            topBadgeContent={data.status >= 300 && data?.sales?.map((sale) => sale.percent)}
                                        />
                                    </div>
                                </div>
                                {/* <div className='flex items-start justify-start my-3'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Espérance de réussite</div>
                                    <div className='text-[#212121] text-base'>
                                        <Badge content={chanceColor(data.chance).name} color={chanceColor(data.chance).color}/>
                                    </div>
                                </div> */}
                                <hr className='w-12 my-5 border-[#212121]'/>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] w-[150px]x text-sm mt-[3px] mr-7'>Date de réception cdc:</div>
                                        <div className='text-[#212121] text-base'>{data.reception_date ? frenchFormat(data.reception_date) : "-"}</div>
                                    </div>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] w-[150px] text-sm mt-[3px]'>{"Date de fin d'analyse:"}</div>
                                        <div className='text-[#212121] text-base'>{data.analysis_date ? frenchFormat(data.analysis_date) : "-"}</div>
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    { data.status >= 200 ? <div className='flex items-start justify-start my-2'>
                                        <div className='text-[#646464] w-[150px] text-sm mt-[3px] mr-4'>Date limite de réponse:</div>
                                        <div className='text-[#212121] text-base'>{data.max_response_date ? frenchFormat(data.max_response_date) : "-"}</div>
                                    </div> : null }
                                    { data.status >= 300 ? <div className='flex items-start justify-start my-2'>
                                        <div className='text-[#646464] w-[150px] text-sm mt-[3px]'>Date de réponse:</div>
                                        <div className='text-[#212121] text-base'>{data.response_date ? frenchFormat(data.response_date) : "-"}</div>
                                    </div> : null }
                                </div>
                                { data.status >= 300 ? <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] w-[150px] text-sm mt-[3px] mr-[17px]'>Chance de réussite:</div>
                                    <div className={`${data.chance ? chanceColor(data.chance).color : "text-[#212121]"} text-base`}>{data.chance ? chanceColor(data.chance).name : "-"}</div>
                                </div> : null }
                            </div>
                        </ContainerCard>
                    </div>
                    : null
            }
            {
                responseGroupVisibility ?
                    <div className='mt-5 mb-5'>
                        <ContainerCard
                            title={'Groupement de réponse'}
                            editButtonDisplayed={!cannotBeModified}
                            onEditButtonClick={() => setResponseGroupPopupView(true)}
                            width='w-full'
                            coloredButtonDisplayed={false}
                            blueStyle={blueStyle}
                        >
                            <div className='flex flex-col'>
                                <div className='flex items-start justify-start'>
                                    <div className='text-[#646464] text-sm w-[100px] mt-[3px] mr-4'>
                                        {data.subsidiary === "Terre d'Avance" ? "Terre d'Avance:" : "Terre d'Avance Formation:"}
                                    </div>
                                    <div className='flex flex-row items-center justify-center'>
                                        {renderTaStatus()}
                                    </div>
                                </div>
                                <hr className='w-12 my-5 border-[#212121]'/>
                                <div className='flex flex-col justify-start first-letter:items-start'>
                                    {partnerValues.map((partner) => {
                                        if (!partner.ressource) return null;

                                        return (
                                            <div key={partner.id} className='flex items-center justify-start'>
                                                <div className='text-[#646464] w-[100px] text-sm mt-[1px] mr-4'>{partner?.ressource?.contractor?.name ? partner.ressource.contractor.name  : " - "}:</div>
                                                <div className='text-[#212121] text-base'>
                                                    {partner.role.name ? partner.role.name : "-"}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* <div className='flex items-start justify-start my-3'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-4'>Espérance de réussite</div>
                                    <div className='text-[#212121] text-base'>
                                        <Badge content={chanceColor(data.chance).name} color={chanceColor(data.chance).color}/>
                                    </div>
                                </div> */}
                            </div>
                        </ContainerCard>
                    </div>
                    : null
            }
            {
                budgetCardVisibility ?
                    <ContainerCard
                        title='Honoraires'
                        editButtonDisplayed={!budgetTableEdit && budgetTableData.length > 0 && !cannotBeModified}
                        onEditButtonClick={() => setBudgetPopupView(true)}
                        coloredButtonDisplayed={false}
                        blueStyle={blueStyle}
                    >
                        {data.type === "business" ?
                            <div className='w-full'>
                                <div className='flex flex-col'>
                                    <div className='flex pb-2 border-b border-gray-200'>
                                        <div className='text-[#646464] text-sm mt-[3px] w-[250px] mr-2'>{data.subsidiary}</div>
                                        <div className='flex items-start justify-between mb-2 '>
                                            <div className='text-[#646464] text-sm mt-[3px] mr-2'>Honoraires:</div>
                                            <div className='text-[#212121] text-base'>
                                                {formatThousands(data.budget)}€ HT
                                            </div>
                                        </div>
                                        <div className='flex w-[200px] items-start justify-end mb-2 ml-10'>
                                            <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Jours d'intervention:"}</div>
                                            <div className='text-[#212121] text-base'>
                                                {data.billable_days}J
                                            </div>
                                        </div>
                                        <div className='flex w-[200px] items-center justify-end mb-2 ml-10'>
                                            <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Montant de TVA:"}</div>
                                            <div className='text-[#212121] text-base'>
                                                {formatThousands(((parseInt(data.budget_vat) / 100) * data.budget).toFixed(2))}€
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center border-b border-gray-200">
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2 w-[250px]'>{"Achats forfaitaires refacturés:"}</div>
                                        <div className='flex items-start justify-between py-4'>
                                            <div className='text-[#646464] text-sm mt-[3px] mr-2'>Honoraires:</div>
                                            <div className='text-[#212121] text-base'>
                                                {formatThousands(data.expense_budget)}€ HT
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center border-b border-gray-200">
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2 w-[250px]'>{"Frais forfaitaires refacturés:"}</div>
                                        <div className='flex items-start justify-between py-4'>
                                            <div className='text-[#646464] text-sm mt-[3px] mr-2'>Honoraires:</div>
                                            <div className='text-[#212121] text-base'>
                                                {formatThousands(data.procurement_cost)}€ HT
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center border-b border-gray-200">
                                        <div className='text-[#646464] text-sm mt-[3px] w-[250px] mr-2'>{"Commissions:"}</div>
                                        <div className='flex items-start justify-between py-4'>
                                            <div className='text-[#646464] text-sm mt-[3px] mr-2'>Honoraires:</div>
                                            <div className='text-[#212121] text-base'>
                                                {formatThousands(data.commission)}€ HT
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr className='w-12 pt-2 border-[#212121]'/> */}
                                    {data.agreement_leads.length ? data.agreement_leads.map((pilot, index) => {
                                        return (
                                            <div key={index} className='flex flex-col'>
                                                <div className="flex items-center w-full py-4 border-b border-gray-200">
                                                    <div className='text-[#646464] text-sm mr-2 w-[250px]'>Budget pilotage {pilot.ressource.first_name + " " + pilot.ressource.last_name}:</div>
                                                    <div className='flex items-center w-[200px]'>
                                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>Honoraires:</div>
                                                        <div className='text-[#212121] text-base'>
                                                            {formatThousands(pilot.budget)}€ HT
                                                        </div>
                                                    </div>
                                                    <div className='flex w-[200px] mb-2 '>
                                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>TJM:</div>
                                                        <div className='text-[#212121] text-base'>
                                                            {formatThousands(pilot.daily_rate)}€ HT
                                                        </div>
                                                    </div>
                                                    <div className='flex items-centerw-[240px]'>
                                                        <div className='text-[#646464] text-sm mr-2'>{"Pilote:"}</div>
                                                        <div className='text-[#212121] text-base'>
                                                            <Badge type='round' content={pilot.ressource.username}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                        : null }
                                </div>
                                <div className='w-full py-4 mb-4'>
                                    {partnerValues.map((partner) => {
                                        if (!partner.ressource) return null;

                                        return (
                                            <div key={partner.id} className="flex items-center justify-start w-full pb-1">
                                                <label className="font-normal text-sm mr-2 text-[#646464] w-[250px]">
                                                    {partner.ressource.contractor?.name}:
                                                </label>
                                                <div className="flex items-center w-[200px]">
                                                    <label className="font-normal text-sm mr-2 text-[#646464]">
                                                        Honoraires:
                                                    </label>
                                                    <div>{formatThousands(partner.ressource.budget)}€ HT</div>
                                                </div>
                                                <div className='flex items-center mb-2 w-[200px]'>
                                                    <label className="font-normal text-sm mr-2 text-[#646464] pl-1 ">
                                                        Paiement:
                                                    </label>
                                                    <div className='flex items-centerw-[200px]'>
                                                        <div>{partner.ressource.direct ? "Direct" : "Indirect"}</div>
                                                    </div>
                                                </div>
                                                <div className='flex items-center w-[240px]'>
                                                    <label className="font-normal text-sm mr-2 text-[#646464] pl-1 ">
                                                        Montant TVA:
                                                    </label>
                                                    <div className='flex items-center'>
                                                        <div>{formatThousands(((parseInt(partner.ressource.budget_vat) / 100) * partner.ressource.budget).toFixed(2))}€ </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className={`flex flex-col justify-between  pt-2 border-t w-full'} gap-y-2`}>
                                    <div className="flex justify-between w-[400px] text-[#646464]">
                                        <p>Total {partnerValuesWithRessource.length ? 'hors co-traitants ' : ''}:</p>
                                        <p>{formatThousands(totalWithoutPartners)} € HT</p>
                                    </div>

                                    {partnerValuesWithRessource.length ?
                                        <div className="flex justify-between w-[400px] text-[#646464]">
                                            <p>Total:</p>
                                            <p>{formatThousands(totalWithPartners)} € HT</p>
                                        </div>
                                        : null}
                                </div>
                                {/* <Table
                                data={budgetTableData}
                                tableConfig={budgetTableConfig}
                                onSaveFunction={onSaveFunction}
                                showSubmitButtons={budgetTableEdit}
                                setShowSubmitButtons={setBudgetTableEdit}
                                submitButtonLoading={submitButtonLoading}
                                setSubmitButtonLoading={setSubmitButtonLoading}
                                blueStyle={blueStyle}
                            /> */}
                                <Error errors={errors}/>
                            </div> :
                            <div className='flex flex-col'>
                                <div className='flex pb-2 border-b border-gray-200'>
                                    <div className='text-[#646464] text-sm mt-[3px] mr-2'>{data.subsidiary}</div>
                                </div>
                                <div className='flex py-2 border-b border-gray-200'>
                                    <div className='flex items-start mb-2 justify-between w-[250px]'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Budget min:"}</div>
                                        <div className='text-[#212121] text-base'>
                                            {data.min_budget ? formatThousands(data.min_budget) : "-"}€ HT
                                        </div>
                                    </div>
                                    <div className='flex w-[200px] items-start justify-end mb-2 ml-10'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Jours d'intervention max:"}</div>
                                        <div className='text-[#212121] text-base'>
                                            {data.billable_days ? data.billable_days : "-"}
                                        </div>
                                    </div>
                                    <div className='flex w-[200px] items-center justify-end mb-2 ml-10'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Montant de TVA min:"}</div>
                                        <div className='text-[#212121] text-base'>
                                            {formatThousands(((1 / parseInt(data.budget_vat)) * data.min_budget).toFixed(2))}€
                                        </div>
                                    </div>
                                </div>
                                <div className='flex py-2 border-b border-gray-200'>
                                    <div className='flex items-start mb-2 justify-between w-[250px]'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Budget max:"}</div>
                                        <div className='text-[#212121] text-base'>
                                            {formatThousands(data.max_budget)}€ HT
                                        </div>
                                    </div>
                                    <div className='flex w-[200px] items-start justify-end mb-2 ml-10'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Jours d'intervention max:"}</div>
                                        <div className='text-[#212121] text-base'>
                                            {data.billable_days ? data.billable_days : "-"}
                                        </div>
                                    </div>
                                    <div className='flex w-[200px] items-center justify-end mb-2 ml-10'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Montant de TVA max:"}</div>
                                        <div className='text-[#212121] text-base'>
                                            {formatThousands(((1 / parseInt(data.budget_vat)) * data.max_budget).toFixed(2))}€
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full pt-4'>
                                    {partnerValues.map((partner) => {
                                        if (!partner.ressource) return null;

                                        return (
                                            <div key={partner.id} className="flex items-center justify-start w-full pb-1">
                                                <div className="flex items-center justify-between w-[250px]">
                                                    <label className="font-normal text-sm mr-2 text-[#646464] w-[150px]">
                                                    Honoraires {partner.ressource.contractor?.name}:
                                                    </label>
                                                    <div>{partner.ressource.budget}€ HT</div>
                                                </div>

                                                <div className='flex items-center justify-end w-[200px] ml-10 mb-2'>
                                                    <label className="font-normal text-sm mr-2 text-[#646464] pl-1 ">
                                                        Paiement:
                                                    </label>
                                                    <div className='flex items-center justify-end'>
                                                        <div>{partner.ressource.direct ? "Direct" : "Indirect"}</div>
                                                    </div>
                                                </div>
                                                <div className='flex items-center w-[240px] justify-end'>
                                                    <label className="font-normal text-sm mr-2 text-[#646464] pl-1 ">
                                                        Montant de TVA:
                                                    </label>
                                                    <div className='flex items-center'>
                                                        <div>{formatThousands(((parseInt(partner.ressource.budget_vat) / 100) * partner.ressource.budget).toFixed(2))}€</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </ContainerCard>
                    : null
            }
            {
                productionCardVisibility ?
                    <div className='mt-5 mb-5'>
                        <ContainerCard
                            title={productionTitle}
                            editButtonDisplayed={!cannotBeModified}
                            onEditButtonClick={() => setProductionPopupView(true)}
                            width='w-full'
                            coloredButtonDisplayed={false}
                            blueStyle={blueStyle}
                        >
                            <div className='flex flex-col'>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Resp. Production</div>
                                        <div className='text-[#212121] text-base'>
                                            {

                                                data.production_lead ?
                                                    <Badge type='round' content={[data.production_lead]}/> :
                                                    "-"
                                            }
                                        </div>
                                    </div>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Ensemble des producteurs</div>
                                        <div className='text-[#212121] text-base'>
                                            {
                                                data?.producers?.length ?
                                                    <Badge type='round' content={data?.producers}/> :
                                                    "-"
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr className='w-12 my-5 border-[#212121]'/>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Début de production</div>
                                        <div className='text-[#212121] text-base'>{data.mission_start_date ? frenchFormat(data.mission_start_date) : "-"}</div>
                                    </div>
                                    <div className='flex items-start justify-start'>
                                        <div className='text-[#646464] text-sm mt-[3px] mr-4'>Fin de production</div>
                                        <div className='text-[#212121] text-base'>{data.mission_end_date ? frenchFormat(data.mission_end_date) : "-"}</div>
                                    </div>
                                </div>
                            </div>
                            <hr className='w-12 my-5 border-[#212121]'/>
                            <div className='pb-2'>
                                {productionRessourcesValues.length > 0 && productionRessourcesValues.map((producer, index) => {
                                    return (
                                        <div key={index} className='flex items-center justify-start '>
                                            <div className='flex items-start justify-start mb-2'>
                                                <div className='text-[#212121] text-base'>
                                                    <Badge type='round' content={[producer.ressource]}/>
                                                </div>
                                            </div>
                                            <div className='flex w-[200px] items-start justify-end mb-2 ml-10'>
                                                <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Jours d'intervention:"}</div>
                                                <div className='text-[#212121] text-base'>
                                                    {producer.billable_days ? producer.billable_days : 0}
                                                </div>
                                            </div>
                                            <div className='flex w-[200px] items-center justify-end mb-2 ml-10'>
                                                <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"TJM:"}</div>
                                                <div className='text-[#212121] text-base'>
                                                    {
                                                        producer.daily_rate ? formatThousands(producer.daily_rate) : 0
                                                    }€ HT
                                                </div>
                                            </div>
                                            <div className='flex w-[200px] items-start justify-end mb-2 ml-10'>
                                                <div className='text-[#646464] text-sm mt-[3px] mr-2'>{"Budget:"}</div>
                                                <div className='text-[#212121] text-base'>
                                                    {producer.billable_days && producer.daily_rate ?
                                                        (formatThousands(parseInt(producer.billable_days) * parseInt(producer.daily_rate).toFixed(2)))  : 0}€ HT
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex items-center justify-start mb-2 border-t border-gray-200 w-[800px] pt-2'>
                                <div className="font-normal text-m text-[#646464]">
                                                    TOTAL:
                                </div>
                                <div className="font-normal text-m text-[#646464] ml-[20.5px] w-[200px] flex justify-end">
                                    Jours: {parseInt(totalInterventionDays.toFixed(2))}
                                </div>
                                <div className="whitespace-pre-line font-normal text-m text-[#646464] ml-10 w-[200px] flex justify-end">
                                    {formatThousands((totalBudget / totalInterventionDays ? totalBudget / totalInterventionDays : 0).toFixed(0))}€ HT
                                </div>
                                <div className=" font-normal text-m text-[#646464] ml-9 w-[200px] flex justify-end">
                                    {formatThousands(parseInt(totalBudget.toFixed(2)))}€ HT
                                </div>
                            </div>
                        </ContainerCard>
                    </div>
                    : null
            }

            <Popup
                view={dealInformationsPopupView}
                setView={setDealInformationsPopupView}
                width={'1098px'}
                yOverflow=''
                xOverflow=''
            >
                <DealInformationsPopup
                    setView={setDealInformationsPopupView}
                    data={data}
                    refreshData={refreshData}
                    blueStyle={blueStyle}
                />
            </Popup>

            <Popup
                view={salesPopupView}
                setView={setSalesPopupView}
                width={'1098px'}
                xOverflow = ''
                yOverflow={salesPopupOverflow}
                maxHeight='45rem'
            >
                <SalesPopup
                    setView={setSalesPopupView}
                    data={data}
                    refreshData={refreshData}
                    blueStyle={blueStyle}
                    setSalesPopupOverflow={setSalesPopupOverflow}
                />
            </Popup>

            <Popup
                view={responseGroupPopupView}
                setView={setResponseGroupPopupView}
                width={'1098px'}
                xOverflow = ''
                // yOverflow={salesPopupOverflow}
                maxHeight='45rem'
            >
                <ResponseGroupPopup
                    partnerValues={partnerValues}
                    setPartnerValues={setPartnerValues}
                    setView={setResponseGroupPopupView}
                    setNewPartnerView={setNewContractorPopupView}
                    newPartnerView={newContractorPopupView}
                    refreshData={refreshData}
                    data={data}
                    blueStyle={blueStyle}
                    // setSalesPopupOverflow={setSalesPopupOverflow}
                />
            </Popup>
            <Popup
                view={budgetPopupView}
                setView={setBudgetPopupView}
                width={'1098px'}
                xOverflow = ''
                maxHeight='45rem'
            >
                <BudgetPopup
                    partnerValues={partnerValues}
                    setPartnerValues={setPartnerValues}
                    setView={setBudgetPopupView}
                    setNewPartnerView={setNewContractorPopupView}
                    newPartnerView={newContractorPopupView}
                    refreshData={refreshData}
                    data={data}
                    blueStyle={blueStyle}
                    // setSalesPopupOverflow={setSalesPopupOverflow}
                />
            </Popup>
            {/* Contractors Popup */}
            <Popup
                view={contractorsPopupView}
                setView={setContractorsPopupView}
                width={'1098px'}
                xOverflow = ''
                yOverflow={salesPopupOverflow}
                maxHeight='45rem'
            >
                <ContractorsPopup
                    setView={setContractorsPopupView}
                    data={data}
                    refreshData={refreshData}
                    blueStyle={blueStyle}
                    setSalesPopupOverflow={setSalesPopupOverflow}
                />
            </Popup>

            <Popup
                view={productionPopupView}
                setView={setProductionPopupView}
                width={'1098px'}
            >
                <ProductionPopup
                    setView={setProductionPopupView}
                    dealData={data}
                    refreshData={refreshData}
                    blueStyle={blueStyle}
                    isInternal={isInternal}
                />
            </Popup>
            <Popup
                view={productionInternalPopupView}
                setView={setProductionInternalPopupView}
                width={'1098px'}
                xOverflow=''
                yOverflow=''
            >
                <ProductionInternalPopup
                    setView={setProductionInternalPopupView}
                    dealData={data}
                    refreshData={refreshData}
                    blueStyle={blueStyle}
                    isInternal={isInternal}
                />
            </Popup>
        </>
    )
}

export default InformationsSection;
