export const formatThousands = (value) => {
    // Retourner vide si null ou undefined
    if (value == null) return "";

    // Convertir en nombre
    const number = Number(value);

    if (isNaN(number)) return value;

    // Récupérer la chaîne d'origine pour préserver le format décimal
    const stringValue = value.toString();
    const [integerPart, decimalPart] = stringValue.split('.');

    // Formatter la partie entière avec des espaces
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Retourner le nombre formaté avec la partie décimale si elle existe
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}